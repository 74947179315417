.img-enlarge-on-hover {
    transition: transform 0.3s ease;
}

/* Scale up the image on hover */
.img-enlarge-on-hover:hover {
    transform: scale(1.2); /* Increase the scale value as per your preference */
}

/* Add a transition to smoothly animate the opacity change */
.word-transition {
    transition: opacity 0.5s ease;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  
  /* Set the initial opacity to 0 for all words except the first one */
  .word-transition:not(:first-child) {
    opacity: 0;
  }