.fade-out {
    opacity: 0;
    transition: opacity 1.5s ease-in-out;
}

.fade-in {
    opacity: 1;
    transition: opacity 1.5s ease-in-out;
}

// .move-left {
//     margin-left: 20px;
//     transition: margin-left 1s ease-in-out;
// }

// .move-right {
//     margin-right: 20px;
//     transition: margin-right 1s ease-in-out;
// }

// .custom-bg-2 {
//     background-color:rgb(97, 97, 104);
//     background-image: linear-gradient(315deg, rgba(255, 255, 255, .9) 0%, rgba(255, 255, 255, .9) 0%),
//     linear-gradient(30deg, #445 12%, transparent 12.5%, transparent 87%, #445 87.5%, #445),
//     linear-gradient(150deg, #445 12%, transparent 12.5%, transparent 87%, #445 87.5%, #445),
//     linear-gradient(30deg, #445 12%, transparent 12.5%, transparent 87%, #445 87.5%, #445),
//     linear-gradient(150deg, #445 12%, transparent 12.5%, transparent 87%, #445 87.5%, #445),
//     linear-gradient(60deg, #99a 25%, transparent 25.5%, transparent 75%, #99a 75%, #99a), 
//     linear-gradient(60deg, #99a 25%, transparent 25.5%, transparent 75%, #99a 75%, #99a);
//     background-size:80px 140px;
//     background-position: 0 0, 0 0, 40px 70px, 40px 70px, 0 0, 40px 70px;
// }

// .custom-bg-2 {
//     background-color: rgba(135, 180, 211, 1);
//     background-size: 15px 15px;
//     background-image:
//     linear-gradient(315deg, rgba(235, 235, 235, .6) 0%, rgba(235, 235, 235, .6) 0%),
//       linear-gradient(45deg, 
//         rgba(255,255,255,0.5) 0%,
//         rgba(255,255,255,0.5) 50%,
//         rgba(255,255,255,0) 50%,
//         rgba(255,255,255,0) 100%
//       ),
//       linear-gradient(90deg, 
//         rgba(255,255,255,0.5) 0%,
//         rgba(255,255,255,0.5) 50%,
//         rgba(255,255,255,0) 50%,
//         rgba(255,255,255,0) 100%
//       );
// }

.custom-bg-2 {
    background: radial-gradient(rgb(221, 221, 221) 15%, rgba(116, 116, 116, 0) 16%), radial-gradient(rgb(224, 224, 224) 15%, rgba(112, 112, 112, 0) 16%), rgb(192, 192, 192);
    background-position: 0 0, 70px 70px;
    background-origin: padding-box;
    background-clip: border-box;
    background-size: 140px 140px;
}