::-webkit-scrollbar {
    width: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 20px;
    height: 60%;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: #777;
}

.fade-enter {
    opacity: 0;
    z-index: 1;
}
.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}
.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-out;
}
  