.parallax-container {
    position: relative;
    overflow: hidden;
}

.parallax-item {
    position: absolute;
    transition: transform 0.1s;
    /* Additional styling */
}
