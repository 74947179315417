/* FadeAndSlideHorizontal.css */
.fade-slide-horizontal-enter {
    opacity: 0;
    transform: translateX(100%);
}

.fade-slide-horizontal-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.fade-slide-horizontal-exit {
    opacity: 1;
    transform: translateX(0);
}

.fade-slide-horizontal-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 300ms, transform 300ms;
}