.sc-button {
    font-size: 2rem;
    margin: 0 0.3rem;
    transition: color 0.4s ease-in-out;
    transition: text-shadow 0.4s ease-in-out;
    // transition: font-size 0.2s ease-in-out;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.7);
    color: #252525;
}

.sc-button:hover {
    color: #e7e7e7;
    text-shadow: 0 0 10px rgba(0, 0, 0, 1);
    // font-size: 2.5rem;
}