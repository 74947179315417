.resume-button {
    margin: 0 0.3rem;
    color: #e2e2e2;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.7);
    background-color: #252525;
    transition: background-color 0.4s ease-in-out;
    transition: box-shadow 0.4s ease-in-out;
    
    a {
        color: #e2e2e2;
        text-decoration: none;
    }
}

.resume-button:hover {
    background-color: #e2e2e2;
    color: #242424;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);

    a {
        color: #242424;
    }
}